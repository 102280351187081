import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['rating', 'star']
  static values  = { rating: Number, received: Boolean }

  rate(event) {
    this.ratingValue = event.params.rating
  }

  ratingValueChanged() {
    this.ratingTarget.value = this.ratingValue

    this.starTargets.forEach(element => {
      let rating       = element.getAttribute('data-satisfaction-form-rating-param')
      let ratedStars   = element.querySelector('.rated_at_least')
      let unratedStars = element.querySelector('.not_rated_at_least')

      if (this.rated_at_least(rating)) {
        ratedStars.classList.remove('d-none')
        unratedStars.classList.add('d-none')
      } else {
        ratedStars.classList.add('d-none')
        unratedStars.classList.remove('d-none')
      }
    })
  }

  rated_at_least(rating) {
    return this.ratingValue && rating <= this.ratingValue
  }

  receivedValueChanged() {
    this.dispatch("receivedValueChanged", { detail: { content: this.receivedValue } })
  }
}
