import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  toggleDestroyMethod(event) {
    event.preventDefault()

    this.element.querySelector('[name="_method"]').value = 'delete'

    let checkedCount = 0

    document.querySelectorAll('[name*="update_list"]').forEach(listInput => {
      listInput.setAttribute('id',   listInput.id.replace('update_list', 'destroy_list'))
      listInput.setAttribute('name', listInput.name.replace('update_list', 'destroy_list'))

      if (listInput.checked) {
        checkedCount += 1
      }
    })

    if (checkedCount === 0) {
      let modelName       = this.element.id.replace('new_', '')

      let stubListInput   = document.createElement('input')
      stubListInput.type  = 'hidden'
      stubListInput.id    = modelName + '_destroy_list'
      stubListInput.name  = modelName + '[destroy_list]'
      stubListInput.value = '[]'
            
      this.element.appendChild(stubListInput);
    }

    this.element.submit()
  }
}
