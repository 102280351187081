import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  clearAllElements(event) {
    event.preventDefault()

    this.element.querySelectorAll('input').forEach(element => {
      if (element.classList.contains('form-control')) {
        element.value = ''
      } else if (element.classList.contains('form-check-input')) {
        element.checked = false
      }
    })
  }
}
