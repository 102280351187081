import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  initialize() {
    console.log("INIT...")
  }

  toggleCorrectAnswer(event) {
    let correctReplies = this.element.querySelectorAll('.correct-reply-setting')

    if (event.target.value === 'true') {
      [...correctReplies].forEach( element => element.classList.remove('d-none') )
    } else if (event.target.value === 'false') {
      [...correctReplies].forEach( element => element.classList.add('d-none') )

      let correctReply = this.element.querySelector("input[id *= 'correct_reply']")

      if (correctReply) {
        correctReply.value = ''
      }

      let payOnlyIfCorrect = this.element.querySelector("select[id *= 'pay_only_if_correct']")

      if (payOnlyIfCorrect) {
        payOnlyIfCorrect.value = 'false'
      }
    }
  }

  togglePayment(event) {
    let paymentSettings = this.element.querySelectorAll('.payment-setting')

    if (event.target.value === 'true') {
      [...paymentSettings].forEach( element => element.classList.remove('d-none') )
    } else if (event.target.value === 'false') {
      [...paymentSettings].forEach( element => element.classList.add('d-none') )

      let payOnlyIfCorrect = this.element.querySelector("select[id *= 'pay_only_if_correct']")

      if (payOnlyIfCorrect) {
        payOnlyIfCorrect.value = 'false'
      }
    }
  }
}
