import { Controller } from '@hotwired/stimulus'
import { Popover }    from 'bootstrap'

export default class extends Controller {
  satisfactionPopover = null;

  connect() {
    let button   = this.element.querySelector('#satisfaction_form_popup')
    let template = this.element.querySelector('#satisfaction_form_template')

    if (button !== null && template !== null) {
      this.satisfactionPopover = Popover.getOrCreateInstance(button, { html: true, sanitize: false, title: 'Feedback for this page', content: "<turbo-frame id='satisfaction_form_frame'>" + template.innerHTML + "</turbo-frame>" })
    }
  }

  closePopup(event) {
    if (event.detail.content === true) {
      setTimeout(() => { this.satisfactionPopover.hide() }, 1700)
    }
  }
}
