import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = { collectionPrefix: String }

  toggleCheckboxes(event) {
    event.preventDefault()

    document.querySelectorAll('[id*="' + this.collectionPrefixValue + '"]').forEach(input => input.checked = event.target.checked)
  }
}
