import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  toggleCollapse(event) {
    let anchor       = event.target.closest('a')
    let expandIcon   = anchor.querySelector('.expand-icon')
    let contractIcon = anchor.querySelector('.contract-icon')

    let isExpanded   = !expandIcon.classList.contains('d-none')
    let isContracted = !contractIcon.classList.contains('d-none')

    if (isExpanded) {
      expandIcon.classList.add('d-none')
      contractIcon.classList.remove('d-none')
    } else if (isContracted) {
      expandIcon.classList.remove('d-none')
      contractIcon.classList.add('d-none')
    }
  }
}
