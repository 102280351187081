import { Controller } from '@hotwired/stimulus'
import { Modal }      from 'bootstrap'

export default class extends Controller {
  static values = { received: Boolean }

  modal = null

  connect() {
    this.modal = Modal.getOrCreateInstance(this.element)
    this.modal.show()
  }

  closeModal(event) {
    if (event.detail.content === true) {
      setTimeout(() => { this.modal.hide() }, 1700)
    }
  }
}
