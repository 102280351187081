import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['source']

  copy(event) {
    event.preventDefault()

    this.sourceTarget.type = 'text'
    this.sourceTarget.select()
    document.execCommand('copy')
    this.sourceTarget.type = 'hidden'

    let enclosingLink = event.target.closest('a')
    let clipboardRest = enclosingLink.querySelector('.clipboard-rest')
    let clipboardOk   = enclosingLink.querySelector('.clipboard-ok')

    clipboardRest.classList.add('d-none')
    clipboardOk.classList.remove('d-none')
  }
}
