import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['svg', 'showText', 'hideText']
  static values  = { show: Boolean }

  toggleSVG(event) {
    this.showValue = !this.showValue

    if (this.showValue) {
      this.svgTarget.classList.remove('visually-hidden')

      this.showTextTarget.classList.add('d-none')
      this.hideTextTarget.classList.remove('d-none')
    } else {
      this.svgTarget.classList.add('visually-hidden')

      this.showTextTarget.classList.remove('d-none')
      this.hideTextTarget.classList.add('d-none')
    }
  }
}
