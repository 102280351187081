import 'bootstrap'
import '@popperjs/core'
import 'moment'

import { Popover, Tooltip } from 'bootstrap'

document.documentElement.addEventListener('turbo:load', () => {
  let tooltipList = [...document.querySelectorAll('[data-bs-toggle="tooltip"]')].map(tooltipElement => new Tooltip(tooltipElement))
  let popoverList = [...document.querySelectorAll('[data-bs-toggle="popover"]')].map(popoverElement => new Popover(popoverElement))
})
