import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  togglePayment(event) {
    let paymentSettings = this.element.querySelectorAll('.payment-setting')

    if (event.target.value === 'no_payment') {
      [...paymentSettings].forEach( element => element.classList.add('d-none') )

      this.element.querySelector("select[id *= payment_destination]").value = 'no_destination'
      this.element.querySelector("input[id *= payment_for_completion]").value = 0
    } else {
      [...paymentSettings].forEach( element => element.classList.remove('d-none') )
    }
  }
}
