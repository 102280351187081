import '@hotwired/turbo-rails'

import * as ActiveStorage from '@rails/activestorage'
ActiveStorage.start()

import 'trix'
import '@rails/actiontext'

import 'configs'
import 'controllers'
import 'channels'

import '../stylesheets/application'

require.context('../images', true)
