import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  chartkickChart = null
  chartjsChart   = null

  static values  = { responseId: String }

  connect() {
    this.chartkickChart = Chartkick.charts['patience-response-chart-' + this.responseIdValue]
    this.chartjsChart   = this.chartkickChart.getChartObject()

    this.chartjsChart.options.scales.y.ticks.callback = (value, index, ticks) => {
      if (value === 0) {
        return 'No'
      } else if (value === 1) {
        return 'Yes'
      } else {
        return value
       }
    }

    this.chartjsChart.options.plugins.tooltip.callbacks.label = (context) => {
      let datasetIndex = context.datasetIndex
      let dataRowIndex = context.dataIndex
      let dataRow      = this.chartkickChart.rawData[datasetIndex].data[dataRowIndex]

      if (dataRow[1] === 0) {
        return 'Right'
      } else if (dataRow[1] === 1) {
        return 'Left'
      } else {
        return dataRow[1]
      }
    }

    this.chartjsChart.update()
  }
}
