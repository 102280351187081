import { Controller } from '@hotwired/stimulus'
import { Carousel }   from 'bootstrap'

export default class extends Controller {
  carousel = null

  static targets = ['carouselWithControls', 'stopLink', 'goLink']

  connect() {
    this.carousel = new Carousel(this.carouselWithControlsTarget)
  }

  previous(event) {
    event.preventDefault()
    this.carousel.prev()
  }

  next(event) {
    event.preventDefault()
    this.carousel.next()
  }

  stop(event) {
    event.preventDefault()
    this.carousel.pause()

    this.stopLinkTarget.classList.add('d-none')
    this.goLinkTarget.classList.remove('d-none')
  }

  go(event) {
    event.preventDefault()
    this.carousel.cycle()

    this.stopLinkTarget.classList.remove('d-none')
    this.goLinkTarget.classList.add('d-none')
  }
}
