import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = []

  toggle(event) {
    let emailIcon = this.element.querySelector('.contact-form-email')
    let smsIcon   = this.element.querySelector('.contact-form-sms')

    if (event.target.value === 'email') {
      emailIcon.classList.remove('d-none')
      smsIcon.classList.add('d-none')
    } else if (event.target.value === 'sms') {
      emailIcon.classList.add('d-none')
      smsIcon.classList.remove('d-none')
    } else {
      emailIcon.classList.add('d-none')
      smsIcon.classList.add('d-none')
    }
  }
}
